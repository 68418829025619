import React, { useEffect, useState } from 'react';
import './SpinningElement.css';
import { TransparentLogo } from '../assets/images';
import { motion } from 'framer-motion';
import { useLocation } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

const SpinningElement: React.FC = () => {
    const [isInContactView, setIsInContactView] = useState(false);
    const location = useLocation();

    useEffect(() => {
        let observer: IntersectionObserver | null = null;

        const contactSection = document.getElementById('contact');
        if (contactSection) {
            observer = new IntersectionObserver(
                (entries) => {
                    entries.forEach((entry) => {
                        setIsInContactView(entry.isIntersecting);
                    });
                },
                { threshold: 0.1 } // Adjust as needed for sensitivity
            );
            observer.observe(contactSection);
        } else {
            // If the contact section is not on the page, ensure the spinning element is visible
            setIsInContactView(false);
        }

        return () => {
            if (observer) {
                observer.disconnect();
            }
        };
    }, [location]); // Re-run when the location changes

    const headerHeight = 64; // Adjust this value to match your Navbar's height

    const scrollWithOffset = (el: HTMLElement) => {
        const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
        const yOffset = -headerHeight;
        window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
    };

    return (
        <motion.div
            initial={{ opacity: 1 }}
            animate={{ opacity: isInContactView ? 0 : 1 }}
            transition={{ duration: 0.5, ease: 'easeInOut' }}
            className="spinning-element-wrapper"
        >
            {/* Use HashLink to navigate to the contact section on the homepage */}
            <HashLink
                to="/#contact"
                scroll={scrollWithOffset}
                smooth
            >
                <div className="spinning-container">
                    {/* Static logo in the center */}
                    <div className="logo-container pulsate">
                        <img src={TransparentLogo} alt="Logo" className="logo-image" />
                    </div>

                    <svg
                        className="circular-text"
                        width="200"
                        height="200"
                        viewBox="0 0 200 200"
                    >
                        <defs>
                            <path
                                id="circlePath"
                                d="
                  M 100, 100
                  m -80, 0
                  a 79,79 0 1,1 160,0
                  a 79,79 0 1,1 -160,0
                "
                            />
                        </defs>
                        <text
                            fill="#002C6E"
                            fontSize="14"
                            fontWeight="bold"
                            textLength="502.65"
                            lengthAdjust="spacingAndGlyphs"
                        >
                            <textPath href="#circlePath" startOffset="0">
                                • Let’s Talk About Your Vision •• Let’s Talk About Creating Impactful Apps •
                            </textPath>
                        </text>
                    </svg>
                </div>
            </HashLink>
        </motion.div>
    );
};

export default SpinningElement;
