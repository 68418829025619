import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import Navbar from "../Components/Navbar";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { blogs } from "../Blogs/blogs.data";

const BlogDetail: React.FC = () => {
    const { id } = useParams<{ id: string }>();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    if (!id) {
        return (
            <>
                <Navbar />
                <div className="pt-20 min-h-screen flex items-center justify-center">
                    <h1 className="text-2xl">Invalid blog ID</h1>
                </div>
            </>
        );
    }

    const blogId = parseInt(id, 10);
    const blog = blogs.find((blog) => blog.id === blogId);

    if (!blog) {
        return (
            <>
                <Navbar />
                <div className="pt-20 min-h-screen flex items-center justify-center">
                    <h1 className="text-2xl">Blog not found</h1>
                </div>
            </>
        );
    }

    return (
        <>
            <Navbar />
            <div className="pt-20 bg-gray-50 min-h-screen">
                <div className="max-w-3xl mx-auto px-4 py-12">
                    <h1 className="text-5xl font-bold mb-6 text-center">{blog.title}</h1>
                    <p className="text-sm text-gray-500 mb-8 text-center">
                        {blog.date} · {blog.author}
                    </p>
                    <div className="mb-12">
                        <img
                            src={blog.image}
                            alt={blog.title}
                            className="w-full rounded-lg shadow-md"
                        />
                    </div>
                    <div className="prose lg:prose-xl prose-blue mx-auto">
                        <ReactMarkdown remarkPlugins={[remarkGfm]}>
                            {blog.content}
                        </ReactMarkdown>
                    </div>
                </div>
            </div>
        </>
    );
};

export default BlogDetail;
