import React from "react";
import Navbar from "../Components/Navbar";
import { Link } from "react-router-dom";
import {blogs} from "../Blogs/blogs.data";

const BlogPage: React.FC = () => {
    return (
        <>
            <Navbar />
            <div className="pt-20 bg-gray-100 min-h-screen">
                <header className="bg-blue-900 text-white py-6">
                    <div className="max-w-7xl mx-auto px-4">
                        <h1 className="text-4xl font-bold">News & Articles</h1>
                        <p className="text-lg mt-2">
                            Stay ahead with the latest trends, expert insights, and practical tips on building innovative software solutions
                            <br /> and scaling your business with technology.
                        </p>
                    </div>
                </header>
                <main className="py-12">
                    <div className="max-w-7xl mx-auto px-4 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
                        {blogs.map((blog) => (
                            <Link
                                key={blog.id}
                                to={`/blogs/${blog.id}`}
                                className="block bg-white rounded-lg shadow-md overflow-hidden hover:shadow-lg transition-shadow duration-300"
                            >
                                <img
                                    src={blog.image}
                                    alt={blog.title}
                                    className="w-full h-48 object-cover"
                                />
                                <div className="p-6">
                                    <p className="text-sm text-gray-500">
                                        {blog.date} · {blog.author}
                                    </p>
                                    <h3 className="mt-2 text-xl font-semibold text-gray-800 hover:text-blue-600">
                                        {blog.title}
                                    </h3>
                                    <p className="mt-4 text-gray-600">{blog.description}</p>
                                    <span className="text-blue-600 mt-4 block font-medium hover:underline">
                    Read more
                  </span>
                                </div>
                            </Link>
                        ))}
                    </div>
                </main>
            </div>
        </>
    );
};

export default BlogPage;
