import React, {useState} from "react";
import {Route, Routes} from "react-router-dom";
import Navbar from "../Components/Navbar";
import PageLoadAnimation from "../Components/Page-Load";
import Footer from "../Components/footer";
import Home from "../Pages/Home";
import Cloud from "../Pages/Cloud";
import { motion } from "framer-motion";
import SpinningElement from "../Components/spinner";
import BlogPage from "../Pages/BlogPage";
import BlogDetail from "../Components/blog-details";

function RoutesComponent() {
    const [isAnimationComplete, setIsAnimationComplete] = useState(false);

    return (
        <>
            {!isAnimationComplete && (
                <PageLoadAnimation onComplete={() => setIsAnimationComplete(true)}/>
            )}

            {isAnimationComplete && (<SpinningElement/>)}

            <div style={{ zIndex: 0}}>

                <Navbar/>
                    <Routes>
                        <Route path="/" element={<Home/>}/>
                        <Route path="/blogs" element={<BlogPage />} />
                        <Route path="/blogs/:id" element={<BlogDetail/>} />
                    </Routes>
                <Footer/>
            </div>
            </>
            );
            }
            export default RoutesComponent;
